import type { BasketModel } from "@commerce/models/basket";

import { Label } from "~/components/ui/label";
import { useFormatPrice } from "~/shop/utils/price";

import {
  getGiftCardTotal,
  getOrderTotal,
} from "../../_._checkout+/utils/totals";

export const PriceSummaryPartial = ({
  summaryData,
  className,
}: {
  summaryData: BasketModel;
  className?: string;
}) => {
  const formatPrice = useFormatPrice();
  if (!summaryData) return null;

  const giftCardPaymentAmount = getGiftCardTotal(summaryData);

  return (
    <div className="mx-auto">
      <div className="w-full flex-row items-start justify-between border-t border-t-gray-200 pt-2">
        <Label className="inline-block py-1 text-lg font-normal text-contrast-black">
          Subtotal:
        </Label>
        <Label className="float-right inline-block py-1 text-lg font-light text-contrast-black">
          {formatPrice(summaryData.productSubTotal)}
        </Label>
      </div>
      {summaryData.orderPriceAdjustments?.map(adjustment => (
        <div
          key={adjustment.creationDate}
          className="w-full flex-row items-start justify-between"
        >
          <Label className="inline-block py-1 text-lg font-normal text-red-11">
            Discount:
          </Label>
          <Label className="float-right inline-block py-1 text-lg font-light text-red-11">
            {formatPrice(adjustment.price)}
          </Label>
        </div>
      ))}
      <div className="w-full flex-row items-start justify-between">
        <Label className="inline-block py-1 text-lg font-normal text-contrast-black">
          Shipping:
        </Label>
        <Label className="float-right inline-block py-1 text-lg font-light text-contrast-black">
          {formatPrice(summaryData.shippingTotal) || "TBD"}
        </Label>
      </div>
      {giftCardPaymentAmount > 0 && (
        <div className="w-full flex-row items-start justify-between">
          <Label className="inline-block py-1 text-lg font-normal text-green-11">
            Gift Card:
          </Label>
          <Label className="float-right inline-block py-1 text-lg font-light text-green-11">
            {formatPrice(-giftCardPaymentAmount)}
          </Label>
        </div>
      )}
      <div className="w-full flex-row items-start justify-between">
        <Label className="inline-block pb-3 pt-1 text-lg font-normal text-contrast-black">
          Sales Taxes:
        </Label>
        <Label className="float-right inline-block pb-3 pt-1 text-lg font-light text-contrast-black">
          {formatPrice(summaryData.taxTotal) || "TBD"}
        </Label>
      </div>
      <div className="w-full flex-row items-start justify-between border-t border-t-gray-200">
        <Label className="inline-block py-3 text-lg font-semibold text-contrast-black">
          Total:
        </Label>
        <Label className="float-right inline-block py-3 text-lg font-normal text-contrast-black">
          {formatPrice(getOrderTotal(summaryData))}
        </Label>
      </div>
    </div>
  );
};
