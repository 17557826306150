/* NB for utils in checkout folder we assume type are for the provider */
import type { BasketModel } from "@commerce/models/basket";

export const getGiftCardTotal = (basket: BasketModel): number => {
  return (
    basket.paymentInstruments?.reduce((totalAmount, paymentInstrument) => {
      return paymentInstrument.paymentMethodId === "GIFT_CERTIFICATE"
        ? totalAmount + (paymentInstrument.amount || 0)
        : totalAmount;
    }, 0) || 0
  );
};

export const getOrderTotal = (basket: BasketModel): number => {
  const giftCardPaymentAmount = getGiftCardTotal(basket);
  const orderTotal = basket.orderTotal
    ? basket?.orderTotal
    : (basket.productTotal || 0) + (basket.shippingTotal || 0);

  return giftCardPaymentAmount > 0
    ? orderTotal - giftCardPaymentAmount
    : orderTotal;
};
